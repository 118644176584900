<template>
  <div>
    <span v-if="feature.featureType === 'access'">
      <el-switch v-model="feature.featureAddonQuantity" active-color="#13ce66" inactive-color="#ff4949" active-value="true" inactive-value="false" @change="onChangeAddOnQuantity"></el-switch>
    </span>
    <span v-else-if="feature.featureType === 'info'">
      {{ $tc('included') }}
    </span>
    <span v-else-if="feature.featureType !== 'access'">
      <el-input v-model.number="feature.featureAddonQuantity" @input="onChangeAddOnQuantity"></el-input>
    </span>
  </div>
</template>
<script>
export default {
  props: {
    feature: {
      required: true,
    },
  },
  data() {
    return {
      model: {
        featureAddonQuantityOld: +this.feature.featureAddonQuantity,
      },
    }
  },
  methods: {
    onChangeAddOnQuantity(value) {
      const _this = this
      if (value === 'true'
        || value === 'false') {
        _this.$emit('update-value', value)
      } else {
        let featureValue = +_this.feature.featureValue
        if (+_this.model.featureAddonQuantityOld > 0 && featureValue > 0) {
          featureValue -= _this.model.featureAddonQuantityOld
        }
        value = +value
        _this.model.featureAddonQuantityOld = value
        _this.$emit('update-value', (featureValue + value).toString())
      }
    },
  },
}
</script>
